import React from "react";
import { useHistory } from "react-router-dom";

export default function Pagination({
  pacientesPorPagina,
  totalPacientes,
  pagina,
  ultimaPagina,
}) {
  let history = useHistory();
  const numeroPaginas = [];
  for (let i = 1; i <= Math.ceil(totalPacientes / pacientesPorPagina); i++) {
    numeroPaginas.push(i);
  }
  return (
    <nav aria-label="...">
      <ul className="pagination justify-content-center">
        {pagina === "1" || pagina === 1 ? (
          <li className="page-item disabled">
            <span className="page-link">Anterior</span>
          </li>
        ) : (
          <li className="page-item">
            <a
              onClick={(e) => {
                e.preventDefault();
                history.push(`/pacientes/${parseInt(pagina) - 1}`);
              }}
              className="page-link"
              href="!#"
            >
              Anterior
            </a>
          </li>
        )}

        {numeroPaginas.map((numero) =>
          parseInt(pagina) === numero ? (
            <li key={numero} className="page-item active">
              <span className="page-link">
                {numero}
                <span className="sr-only">(current)</span>
              </span>
            </li>
          ) : (
            <li key={numero} className="page-item">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/pacientes/${numero}`);
                }}
                className="page-link"
                href="!#"
              >
                {numero}
              </a>
            </li>
          )
        )}
        {parseInt(pagina) === ultimaPagina ? (
          <li className="page-item disabled">
            <span className="page-link">Proxima</span>
          </li>
        ) : (
          <li className="page-item">
            <a
              onClick={(e) => {
                e.preventDefault();
                history.push(`/pacientes/${parseInt(pagina) + 1}`);
              }}
              className="page-link"
              href="!#"
            >
              Próxima
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}
