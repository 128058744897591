import React, { useState } from "react";
import api from "../../../services/api";
import InputMask from "react-input-mask";
import { useHistory } from "react-router-dom";

export default function CadastraPaciente() {
  let history = useHistory();
  const [avatar, setAvatar] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [street, setStreet] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [nickname, setNickname] = useState("");
  const [birth_date, setBirth_date] = useState("");
  const [occupation, setOccupation] = useState("");
  const [gender, setGender] = useState("male");
  const [hobbies, setHobbies] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");

  const enviarFormulario = async (e) => {
    e.preventDefault();

    const data = new FormData();
    
    if(avatar) data.append("avatar", avatar);
    if(name) data.append("name", name);
    if(email) data.append("email", email);
    if(phone) data.append("phone", phone);
    if(mobile) data.append("mobile", mobile);
    if(postal_code) data.append("postal_code", postal_code);
    if(street) data.append("street", street);
    if(complement) data.append("complement", complement);
    if(city) data.append("city", city);
    if(state) data.append("state", state);
    if(password) data.append("password", password);
    if(neighborhood) data.append("neighborhood", neighborhood);
    if(nickname) data.append("nickname", nickname);
    if(birth_date) data.append("birth_date", birth_date);
    if(occupation) data.append("occupation", occupation);
    if(gender) data.append("gender", gender);
    if(hobbies) data.append("hobbies", hobbies);
    if(facebook) data.append("facebook", facebook);
    if(instagram) data.append("instagram", instagram);
    if(cpf) data.append("cpf", cpf);

    if (name === "" || email === "" || password === "") {
      setError("Verifique se os campos estão preenchidos!");
    } else {
      api
        .post("/clients", data, {headers: {
          "Content-Type": "multipart/form-data",
        }})
        .then((response) => {
          history.push("/pacientes");
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setError(Object.values(error.response.data.errors)[0][0]);
          }
        });
    }
  };
  return (
    <form onSubmit={enviarFormulario}>
      {error && (
        <div className="alert alert-danger">
          <span>
            <strong>{error}</strong>
          </span>
        </div>
      )}
      <div className="row">
        <div className="col-md-12 pr-1">
          <div className="form-group">
            <label>Foto Perfil</label>
            <input
              type="file"
              name="avatar"
              className="form-control"
              accept="image/*"
              onChange={(e) => setAvatar(e.target.files[0])}
            />
          </div>
        </div>
        <div className="col-md-6 pr-1">
          <div className="form-group">
            <label>Nome</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nome"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6 pl-1">
          <div className="form-group">
            <label>Apelido</label>
            <input
              type="text"
              className="form-control"
              placeholder="Apelido"
              onChange={(e) => setNickname(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 pr-1">
          <div className="form-group">
            <label>Data de Nascimento</label>
            <input
              type="date"
              className="form-control"
              placeholder="Data de nascimento"
              onChange={(e) => setBirth_date(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 pr-1 pl-1">
          <div className="form-group">
            <label>CPF</label>
            <InputMask
              mask="999.999.999-99"
              type="text"
              className="form-control"
              placeholder="CPF"
              onChange={(e) => setCpf(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 pl-1">
          <div className="form-group">
            <label>Genero</label>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="form-control"
            >
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
              <option value="other">Outros</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 pr-1">
          <div className="form-group">
            <label>Trabalho</label>
            <input
              type="text"
              className="form-control"
              placeholder="Trabalho"
              onChange={(e) => setOccupation(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 pl-1 pr-1">
          <div className="form-group">
            <label>Passa tempo</label>
            <input
              type="text"
              className="form-control"
              placeholder="Passa tempos"
              onChange={(e) => setHobbies(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-2 pr-1 pl-1">
          <div className="form-group">
            <label>Telefone</label>
            <InputMask
              mask="(99) 9999-9999"
              type="text"
              className="form-control"
              placeholder="(xx)1234-4567"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-2 pl-1">
          <div className="form-group">
            <label>Celular</label>
            <InputMask
              mask="(99) 9-9999-9999"
              type="text"
              className="form-control"
              placeholder="(xx)12345-6789"
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 pr-1">
          <div className="form-group">
            <label>Endereço</label>
            <input
              type="text"
              className="form-control"
              placeholder="Rua/Avenida/Estrada..."
              onChange={(e) => setStreet(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 pl-1 pr-1">
          <div className="form-group">
            <label>Bairro</label>
            <input
              type="text"
              className="form-control"
              placeholder="Bairro"
              onChange={(e) => setNeighborhood(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 pl-1">
          <div className="form-group">
            <label>Complemento</label>
            <input
              type="text"
              className="form-control"
              placeholder="casa/apartamento/bloco..."
              onChange={(e) => setComplement(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 pr-1">
          <div className="form-group">
            <label>Cidade</label>
            <input
              type="text"
              className="form-control"
              placeholder="Cidade"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 px-1">
          <div className="form-group">
            <label>Estado</label>
            <input
              type="text"
              className="form-control"
              placeholder="Estado"
              onChange={(e) => setState(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4 pl-1">
          <div className="form-group">
            <label>Cep</label>
            <InputMask
              mask="99999-999"
              type="text"
              className="form-control"
              placeholder="cep"
              onChange={(e) => setPostal_code(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
      <div className="col-md-6 pr-1">
        <div className="form-group">
          <label>Facebook</label>
          <input
            className="form-control"
            type="facebook"
            placeholder="URL do facebook"
            value={facebook}
            onChange={(e) => setFacebook(e.target.value)}
          />
        </div>
      </div>
      <div className="col-md-6 pl-1">
        <div className="form-group">
          <label>Instagram</label>
          <input
            className="form-control"
            type="instagram"
            placeholder="Usuário do Instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
        </div>
      </div>
    </div>
      <div className="row">
        <div className="col-md-6 pr-1">
          <div className="form-group">
            <label>E-mail</label>
            <input
              className="form-control"
              type="email"
              placeholder="exemplo@email.com"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6 pl-1">
          <div className="form-group">
            <label>Senha</label>
            <input
              className="form-control"
              type="password"
              placeholder="Senha"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="update ml-auto mr-2">
          <button type="submit" className="btn btn-primary btn-round">
            cadastrar
          </button>
        </div>
      </div>
    </form>
  );
}
