import React from "react";
import { Link } from "react-router-dom";
import { logout } from "../../../services/auth";
import Logo from "../../../assets/img/osto-logo.png";

export default function sidebar(props) {
  const handleLogout = (e) => {
    logout();
  };
  if (props.isAdmin) {
    return (
      <div
        className="sidebar ps ps--active-y"
        id="navbar"
        s
        data-color="white"
        data-active-color="success"
      >
        <div className="logo">
          <Link to="/cadastro" className="simple-text logo-normal">
            <div className="logo-image-medium">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            <li className={props.isVisualizar ? "active" : ""}>
              <Link to="/pacientes">
                <i className="nc-icon nc-bullet-list-67"></i>
                <p>Visualizar clientes</p>
              </Link>
            </li>
            <li className={props.isCadastrar ? "active" : ""}>
              <Link to="/cadastro">
                <i className="nc-icon nc-simple-add"></i>
                <p>Cadastrar clientes</p>
              </Link>
            </li>
            <li className="">
              <Link to="/" onClick={handleLogout}>
                <i className="nc-icon nc-simple-remove"></i>
                <p>sair</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="sidebar ps ps--active-y"
        id="navbar"
        s
        data-color="white"
        data-active-color="success"
      >
        <div className="logo">
          <Link to="/perfil" className="simple-text logo-normal">
            <div className="logo-image-medium">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav justify-content-end">
            <li className="nav-item">
              <Link to="/" onClick={handleLogout}>
                <i className="nc-icon nc-simple-remove"></i>
                <p>sair</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
