import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";

import SignIn from "./pages/SignIn";
import Home from "./pages/Admin/Home";
import ShowPacientes from "./pages/Admin/Pacientes";
import Perfil from "./pages/Admin/Perfil";
import PerfilEdit from "./pages/Admin/PerfilEdit";
import Documentos from "./pages/Admin/Documentos";
import Prontuarios from "./pages/Admin/Prontuarios";
import Prontuario from "./pages/Admin/Prontuario";

import PacientePerfil from "./pages/Paciente/Home";
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);
const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={SignIn} />
      <PrivateRoute path="/cadastro" component={Home} />
      <PrivateRoute path="/pacientes/:pagina?" component={ShowPacientes} />
      <PrivateRoute path="/paciente/:id/show" component={Perfil} />
      <PrivateRoute path="/paciente/:id/edit" component={PerfilEdit} />
      <PrivateRoute path="/paciente/:id/document" component={Documentos} />
      <PrivateRoute
        path="/paciente/:id/lista-prontuarios"
        component={Prontuarios}
      />
      <PrivateRoute
        path="/paciente/:id/prontuario/:idProntuario"
        component={Prontuario}
      />
      <PrivateRoute path="/perfil" component={PacientePerfil} />

      <Route path="*" component={() => <h1>Pagina não encontrada</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
