import React, { useState, useEffect } from "react";
import { Excluir } from "./styles";
import { Link, useLocation } from "react-router-dom";
import api from "../../../services/api";
import Pagination from "./Pagination";

export default function ListaPacientes({ pagina = 1 }) {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const name = searchParams.get("name");
  const paginaAtual = pagina;
  const [pacientes, setPacientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPacientes, setTotalPacientes] = useState();
  const [pacientesPorPagina, setpacientesPorPagina] = useState();
  const [ultimaPagina, setUltimaPagina] = useState();

  useEffect(() => {
    const fetchPacientes = async () => {
      setLoading(true);
      if (name) {
        const response = await api.get(`/clients?name=${name}`).then();
        setpacientesPorPagina(response.data.per_page);
        setUltimaPagina(response.data.last_page);
        setTotalPacientes(response.data.total);
        setPacientes(response.data.data);
      } else {
        const response = await api.get(`/clients?page=${paginaAtual}`).then();
        setpacientesPorPagina(response.data.per_page);
        setUltimaPagina(response.data.last_page);
        setTotalPacientes(response.data.total);
        setPacientes(response.data.data);
      }

      setLoading(false);
    };
    fetchPacientes();
  }, [paginaAtual, name]);

  if (loading) {
    return (
      <div className="alert alert-info">
        <i className="fas fa-spinner fa-pulse"></i>{" "}
        <strong>Carregando Pacientes</strong>
      </div>
    );
  } else {
    return (
      <div className="table-responsive">
        <Pagination
          pacientesPorPagina={pacientesPorPagina}
          totalPacientes={totalPacientes}
          pagina={paginaAtual}
          ultimaPagina={ultimaPagina}
        />
        <table className="table table-striped">
          <thead className=" text-primary">
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Telefone</th>
              <th className="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            {pacientes.map((paciente) => (
              <tr key={paciente.id}>
                <td>{paciente.name}</td>
                <td>{paciente.email}</td>
                <td>
                  {paciente.phone ? paciente.phone : "Sem telefone cadastrado"}{" "}
                  <br />
                </td>
                <td className="text-right">
                  <Link to={`/paciente/${paciente.id}/show`}>
                    <button className="btn btn-sm btn-primary mr-1 ml-1">
                      <i className="fa fa-user"></i> Visualizar
                    </button>
                  </Link>

                  <Excluir
                    onClick={async (e) => {
                      if (
                        window.confirm(`voce quer excluir ${paciente.name} ?`)
                      ) {
                        await api.delete(`/clients/${paciente.id}`).then();
                        window.location.reload();
                      }
                    }}
                  >
                    <button className="btn btn-sm btn-danger mr-1 ml-1">
                      <i className="fa fa-trash"></i> Excluir
                    </button>
                  </Excluir>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          pacientesPorPagina={pacientesPorPagina}
          totalPacientes={totalPacientes}
          pagina={paginaAtual}
          ultimaPagina={ultimaPagina}
        />
      </div>
    );
  }
}
