import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

export default function NavBar(props) {
  const [busca, setBusca] = useState("");
  const history = useHistory()
  function mostraMenu(e) {
    e.preventDefault();
    let navbar = document.getElementById("navbar");
    let main = document.getElementById("main");
    let menu = document.getElementById("menu-hamburger");
    if (
      navbar.style.transform === "" ||
      navbar.style.transform === "translate3d(-260px,0,0)"
    ) {
      navbar.style.transform = " translate3d(0px,0,0)";
      main.style.transform = "translate3d(260px,0,0)";
      menu.classList.add("toggled");
    } else {
      navbar.style.transform = "";
      main.style.transform = "";
      menu.classList.remove("toggled");
    }
  }

  function mostraDetalhes(e) {
    e.preventDefault();
    let navbar = document.getElementById("top-navbar");
    let button = document.getElementById("button-point");
    let menu = document.getElementById("navigation");
    if (navbar.classList.contains("navbar-transparent")) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
      button.classList.remove("collapsed");
      menu.classList.add("show");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
      button.classList.add("collapsed");
      menu.classList.remove("show");
    }
  }

  function pesquisa(e){
    if (e.key === 'Enter') {
      e.preventDefault();
      history.push(`/pacientes/?name=${busca}`);
    }
  }
  return (
    <nav
      className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent"
      id="top-navbar"
    >
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <div className="navbar-toggle" id="menu-hamburger">
            <button
              type="button"
              onClick={mostraMenu}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar bar1"></span>
              <span className="navbar-toggler-bar bar2"></span>
              <span className="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <Link to="/pacientes" className="navbar-brand">
            Espaço Osteo Reabilith
          </Link>
        </div>
        <button
          id="button-point"
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="navigation"
          aria-controls="navigation-index"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={mostraDetalhes}
        >
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
          <span className="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div
          className="navbar-collapse justify-content-end collapse"
          id="navigation"
        >
          <form>
            <div className="input-group no-border">
              <input
                type="text"
                className="form-control"
                placeholder="Buscar..."
                onChange={(e) => setBusca(e.target.value)}
                onKeyDown={pesquisa}
              ></input>
              <div className="input-group-append">
                <Link to={`/pacientes/?name=${busca}`}>
                  <div className="input-group-text">
                    <i className="nc-icon nc-zoom-split"></i>
                  </div>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </nav>
  );
}
