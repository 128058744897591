export default function Card(props){
    return(
        <div className="card">
            <div className="card-header">
            <span className="h4">
                {props.cardTitle}
            </span>
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </div>
    )
}