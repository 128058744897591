import { createGlobalStyle } from "styled-components";

const GlobalStyled = createGlobalStyle`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}
body, html {
  background: #f4f3ef;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100vh;
  width: 100vw;
}
`;

export default GlobalStyled;