import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import serialize from "form-serialize";
export default function ProntuarioDetail({ pacienteId, prontuarioId }) {
  let history = useHistory();
  const [perguntas, setPerguntas] = useState([]);
  const [titulo, setTitulo] = useState("");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function carregaDados() {
      setLoading(true);
      const response = await api.get(
        `/clients/${pacienteId}/medical-records/${prontuarioId}/questions`
      );
      setPerguntas(response.data.questions);
      setTitulo(response.data.title);
      console.log('====================================');
      console.log(response.data);
      console.log('====================================');
      setLoading(false);
    }
    carregaDados();
  }, [pacienteId, prontuarioId]);

  function enviaFormulario(e) {
    var form = document.querySelector("#example-form");
    var obj = serialize(form, { hash: true });

    async function envia() {
      try {
        await api.post(
          `/clients/${pacienteId}/medical-records/${prontuarioId}/questions`,
          obj
        );
      } catch (error) {
        console.log(error);
      }
    }
    envia();
    history.push(`/paciente/${pacienteId}/lista-prontuarios`);
  }

  if (loading) {
    return (
      <div className="alert alert-info">
        <i className="fas fa-spinner fa-pulse"></i>{" "}
        <strong>Carregando Perguntas</strong>
      </div>
    );
  } else {
    return (
      <div className="card card-user">
        <div className="card-header">
          <h5>{titulo}</h5>
          <form id="example-form">
            {perguntas.map((pergunta) => (
              <div key={pergunta.id} className="form-group">
                {pergunta.type === "textarea" ? (
                  <div className="form-group">
                    <label>{pergunta.title}</label>
                    <textarea
                      className="form-control"
                      name={`questions[${pergunta.id}]`}
                      defaultValue={pergunta.response}
                    ></textarea>
                  </div>
                ) : pergunta.type === "select" ? (
                  <div className="form-group">
                    <label>{pergunta.title}</label>
                    <select
                      className="form-control"
                      name={`questions[${pergunta.id}]`}
                    >
                      {pergunta.options.map((opcao) =>
                        opcao === pergunta.response ? (
                          <option key={opcao} defaultValue={opcao} selected>
                            {opcao}
                          </option>
                        ) : (
                          <option key={opcao} defaultValue={opcao}>
                            {opcao}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                ) : pergunta.type === "input:text" ? (
                  <div className="form-group">
                    <input
                      className="form-control"
                      name={`questions[${pergunta.id}]`}
                      type="text"
                    >
                      {pergunta.response}
                    </input>
                  </div>
                ) : pergunta.type === "title" ? (
                  <label>{pergunta.title}</label>
                ) : pergunta.type === "checkbox"  && pergunta.response==='on'? (
                  <div className="form-check">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={`questions[${pergunta.id}]`}
                        checked
                      />
                      {pergunta.title}
                      <span className="form-check-sign">
                        <span className="check"></span>
                      </span>
                    </label>
                  </div>
                ) : (
                  <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={`questions[${pergunta.id}]`}
                      
                    />
                    {pergunta.title}
                    <span className="form-check-sign">
                      <span className="check"></span>
                    </span>
                  </label>
                </div>
                )}
              </div>
            ))}

            <button
              onClick={enviaFormulario}
              type="button"
              className="btn btn-success btn-block"
            >
              Enviar
            </button>
          </form>
        </div>
      </div>
    );
  }
}
