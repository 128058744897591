import React, { Component } from "react";
import SideBar from "../../../components/Shared/sidebar";
import NavBar from "../../../components/Shared/navbar";
import ListaPacientes from "../../../components/Lista/listaPacientes";

export default class ShowPacientes extends Component {
  render() {
    let { pagina } = this.props.match.params;
    return (
      <div className="wrapper ">
        <SideBar isVisualizar isAdmin />
        <div className="main-panel" id="main">
          <NavBar rota="/pacientes" name="Pacientes" />
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title"> Pacientes</h4>
                  </div>
                  <div className="card-body">
                    <ListaPacientes pagina={pagina} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
