import React, { Component } from "react";
import SideBar from "../../../components/Shared/sidebar";
import NavBar from "../../../components/Shared/navbar";

import PerfilShow from "../../../components/Perfil";
import DocumentosShow from "../../../components/Perfil/document";

import { Breadcrumb } from "react-bootstrap";

export default class Documentos extends Component {
  render() {
    let { id } = this.props.match.params;
    return (
      <div className="wrapper ">
        <SideBar isAdmin />
        <div className="main-panel" id="main">
          <NavBar rota="#" name="Paciente" />
          <div className="content">
            <Breadcrumb>
              <Breadcrumb.Item href="/pacientes">
                Lista Paciente
              </Breadcrumb.Item>
              <Breadcrumb.Item href={`/paciente/${id}/show`}>
                Paciente
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Documentos</Breadcrumb.Item>
            </Breadcrumb>
            <div className="row">
              <div className="col-md-4">
                <PerfilShow id={id} page="doc" />
              </div>
              <div className="col-md-8">
                <DocumentosShow id={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
