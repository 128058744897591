import React, { useState, useEffect } from "react";
import "./styles";
import moment from "moment";
import api from "../../services/api";
import { Modal } from "react-bootstrap";

export default function DocumentosShow({ id }) {
  //carrega os documentos no inicio da pagina
  const [loading, setLoading] = useState(false);
  const [documentos, setDocumentos] = useState([]);
  useEffect(() => {
    async function carregaDados() {
      setLoading(true);
      const response = await api.get(`/clients/${id}/documents`).then();
      setDocumentos(response.data);
      setLoading(false);
    }
    carregaDados();
  }, [id]);
  //formulario de envio do documento
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loadingForm, setLoadingForm] = useState(false);
  const data = new FormData();
  data.append("title", title);
  data.append("file", file);
  const EnviaDocumento = async (e) => {
    e.preventDefault();
    if (!title || !file) {
      setError("Verifique o Formulario");
    } else {
      await api
        .post(`/clients/${id}/documents`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(setLoadingForm(true))
        .catch((erro) => setError);
      window.location.reload();
    }
  };

  //modal
  const [modalShow, setModalShow] = useState(false);
  if (loading) {
    return (
      <div className="alert alert-info">
        <i className="fas fa-spinner fa-pulse"></i>{" "}
        <strong>Carregando Documentos</strong>
      </div>
    );
  } else {
    return (
      <div className="card card-user">
        <Modal show={modalShow} onHide={() => setModalShow(false)}>
          <Modal.Header className="modal-title" closeButton>
            <h5>Envio de Documento</h5>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={EnviaDocumento}>
              {error && (
                <div className="alert alert-danger">
                  <span>
                    <strong>{error}</strong>
                  </span>
                </div>
              )}
              {loadingForm && (
                <div className="alert alert-info ">
                  <i className="fas fa-spinner fa-spin"></i> Carregando
                </div>
              )}
              <div className="row">
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nome do documento"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <input
                    type="file"
                    className="form-control"
                    name="file"
                    placeholder="Selecione o arquivo"
                    accept="image/*,.pdf"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </div>
              <button type="submit" className="btn btn-primary btn-block">
                Enviar Documento
              </button>
            </form>
          </Modal.Body>
        </Modal>
        <div className="card-header">
          <div className="row">
            <div className=" ml-3">
              <h5 className="card-title ">Documentos</h5>
            </div>
            <div className="col-md-2 mr-5 ml-auto">
              <button
                type="button"
                className="btn btn-info btn-lg"
                onClick={() => setModalShow(true)}
              >
                <i className="fas fa-file-upload"></i> <br />
              </button>
            </div>
          </div>
        </div>
        <div className="card-body">
          {documentos.length ? (
            <table className="table table-striped">
              <thead className=" text-primary">
                <tr>
                  <th>Nome</th>
                  <th>data de criação</th>
                  <th className="text-right">ação</th>
                </tr>
              </thead>
              <tbody>
                {documentos.map((doc) => (
                  <tr key={doc.id}>
                    <td>{doc.title}</td>
                    <td>
                      {moment(doc.created_at).format("DD/MM/YYYY - HH:mm")}
                    </td>
                    <td className="text-right">
                      <a
                        href={doc.file_url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ margin: "2px" }}
                      >
                        <button type="button" className="btn btn-info ">
                          <i className="fas fa-file-download"></i>
                        </button>
                      </a>

                      <button
                        onClick={async (e) => {
                          if (
                            window.confirm(`voce quer excluir ${doc.title} ?`)
                          ) {
                            await api
                              .delete(
                                `/clients/${doc.client_id}/documents/${doc.id}`
                              )
                              .then();
                            window.location.reload();
                          }
                        }}
                        type="button"
                        className="btn btn-danger"
                      >
                        <i className="far fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              className="alert alert-danger ml-auto mr-auto col-6 mt-5"
              role="alert"
            >
              <i className="fas fa-exclamation-triangle"></i> {""}
              <strong>Não foi encontrado nenhum documento.</strong>
            </div>
          )}
        </div>
      </div>
    );
  }
}
