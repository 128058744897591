import React, { Component } from "react";
import moment from "moment";

import SideBar from "../../../components/Shared/sidebar";
import Card from "../../../components/Shared/card";
import api from "../../../services/api";

export default class Paciente extends Component {
  state = {
    documentos: [],
  };

  async componentDidMount() {
    const response = await api.get("/user-documents");

    this.setState({
      documentos: response.data,
    });
  }

  render() {
    const { documentos } = this.state;
    return (
      <div className="wrapper">
        <SideBar />
        <div className="main-panel" id="main">
          <div className="content">
            <div className="row">
              <div className="col-md-12 ">
                <Card cardTitle="Documentos">
                  {documentos.length ? (
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>Titulo</th>
                          <th>Data</th>
                          <th className="text-right">Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentos.map((documento) => (
                          <tr key={documento.id}>
                            <td>
                              {documento.title
                                ? documento.title
                                : "Não existe documento"}
                            </td>
                            <td>
                              {moment(documento.created_at).format(
                                "DD/MM/YYYY - HH:mm"
                              )}
                            </td>
                            <td className="text-right">
                              <a
                                href={documento.file_url}
                                target="_blank"
                                rel="noreferrer"
                                style={{ margin: "2px" }}
                              >
                                <button type="button" className="btn btn-info ">
                                  <i className="fas fa-file-download"></i>
                                </button>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div
                      className="alert alert-danger ml-auto mr-auto col-6 mt-5"
                      role="alert"
                    >
                      <i className="fas fa-exclamation-triangle"></i> {""}
                      <strong>Não foi encontrado nenhum documento.</strong>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
