import styled from "styled-components";

export const Lista = styled.ul`
  list-style: none;
  text-align: left;
  margin-left: 2rem;
  li {
    margin-top: 1rem;
    font-weight: bold;
  }
`;
