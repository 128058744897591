import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "../Shared/card";

import api from "../../services/api";

export default function ProntuarioShow({ id }) {
  const [prontuarios, setProntuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function carregaDados() {
      setLoading(true);
      const response = await api.get(`/clients/${id}/medical-records`);
      setProntuarios(response.data);
      setLoading(false);
    }
    carregaDados();
  }, [id]);
  if (loading) {
    return (
      <div className="alert alert-info">
        <i className="fas fa-spinner fa-pulse"></i>{" "}
        <strong>Carregando Prontuarios</strong>
      </div>
    );
  } else {
    return (
      <Card cardTitle="Prontuarios">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Titulo</th>
              <th>Preenchido</th>
              <th className="text-right">Ação</th>
            </tr>
          </thead>
          <tbody>
            {prontuarios.map((prontuario) => (
              <tr key={prontuario.id}>
                <td>{prontuario.title}</td>
                <td>
                  <strong>{prontuario.finish === 0 ? "NÃO" : "SIM"}</strong>
                </td>
                <td className="text-right">
                  <Link to={`/paciente/${id}/prontuario/${prontuario.id}`}>
                    <button
                      type="button"
                      rel="tooltip"
                      className="btn btn-success mr-1"
                    >
                      <i className="fa fa-edit"></i> Editar
                    </button>
                  </Link>
                  <a
                    href={prontuario.pdf}
                    target="_blank"
                    rel="noreferrer"
                    style={{ margin: "2px" }}
                  >
                    <button
                      type="button"
                      rel="tooltip"
                      className="btn btn-info ml-1"
                      href={prontuario.pdf}
                    >
                      <i className="fas fa-file-download"></i> Download
                    </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    );
  }
}
