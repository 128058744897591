import React, { Fragment } from 'react';
import Routes from './routes'
import GlobalStyled from './styles/global'

import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/paper-dashboard.css";

const App = () => (
    <Fragment>
        <Routes />
        <GlobalStyled />
    </Fragment>
)
export default App;