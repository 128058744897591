import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../services/api";
import PerfilFoto from "../../assets/img/default-avatar.png";
import Header from "../../assets/img/header.jpg";
import { Lista } from "./styles";

export default function PerfilShow({ id, page }) {
  const history = useHistory();
  const [name, setName] = useState("");
  const [PerfilFoto, setAvatar] = useState("");

  useEffect(() => {
    async function carregaDados() {
      const response = await api.get(`/clients/${id}`).then();
      setName(response.data.name);
      setAvatar(response.data.avatar_url);
    }
    carregaDados();
  }, [id]);

  return (
    <div className="card card-user">
      <div className="image">
        <img src={Header} alt="..." />
      </div>
      <div className="card-body">
        <div className="author">
          <img className="avatar border-gray" src={PerfilFoto} alt="..." />
          <h5 className="card-title">{name}</h5>
        </div>
        <div className="button-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6 ml-auto">
              <Link to={`/paciente/${id}/edit`}>
                <button type="button" className="btn btn-primary btn-block">
                  <i className="fas fa-user-edit"></i> <br />
                  Editar
                </button>
              </Link>
            </div>
            <div className="col-lg-6 col-md-6 col-6 ml-auto mr-auto">
              <a
                href="excluir"
                onClick={async (e) => {
                  e.preventDefault();
                  if (window.confirm(`voce quer excluir ${name} ?`)) {
                    await api.delete(`/clients/${id}`);
                    history.push("/pacientes");
                  }
                }}
              >
                <button type="button" className="btn btn-danger btn-block">
                  <i className="far fa-trash-alt"></i> <br />
                  Excluir
                </button>
              </a>
            </div>
          </div>
          <hr />

          <Lista>
            <li>
              {page === "inf" ? (
                <Link to={`/paciente/${id}/show`}>
                  <i className="fa fa-user"></i> informações
                </Link>
              ) : (
                <Link
                  to={`/paciente/${id}/show`}
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  <i className="fa fa-user"></i> informações
                </Link>
              )}
            </li>
            <li>
              {page === "doc" ? (
                <Link to={`/paciente/${id}/document`}>
                  <i className="fa fa-file"></i> Documentos
                </Link>
              ) : (
                <Link
                  to={`/paciente/${id}/document`}
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  <i className="fa fa-file"></i> Documentos
                </Link>
              )}
            </li>
            <li>
              {page === "prontuario" ? (
                <Link to={`/paciente/${id}/lista-prontuarios`}>
                  <i className="fas fa-file-prescription"></i> Prontuarios
                </Link>
              ) : (
                <Link
                  to={`/paciente/${id}/lista-prontuarios`}
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  <i className="fas fa-file-prescription"></i> Prontuarios
                </Link>
              )}
            </li>
          </Lista>
        </div>
      </div>
    </div>
  );
}
