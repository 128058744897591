import React, { Component } from "react";

import SideBar from "../../../components/Shared/sidebar";
import NavBar from "../../../components/Shared/navbar";
import CadastraPaciente from "../../../components/Forms/cadastraPaciente";

import { withRouter } from "react-router-dom";

class Home extends Component {
  render() {
    return (
      <div className="wrapper">
        <SideBar isCadastrar  isAdmin  />
        <div className="main-panel" id="main">
          <NavBar />
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">  Cadastro de paciente</h4>
                  </div>
                  <div className="card-body">
                    <CadastraPaciente />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
