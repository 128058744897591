import React, { useState, useEffect } from "react";
import api from "../../services/api";
import Card from "../Shared/card";
import moment from "moment";

export default function Detail({ id }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [street, setStreet] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [nickname, setNickname] = useState("");
  const [birth_date, setBirth_date] = useState("");
  const [occupation, setOccupation] = useState("");
  const [gender, setGender] = useState("male");
  const [hobbies, setHobbies] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");

  useEffect(() => {
    async function carregaDados() {
      setLoading(true);
      const response = await api.get(`/clients/${id}`).then();
      console.log(response.data);
      setName(response.data.name);
      setEmail(response.data.email);
      setPhone(response.data.phone);
      setMobile(response.data.mobile);
      setPostal_code(response.data.postal_code);
      setStreet(response.data.street);
      setComplement(response.data.complement);
      setCity(response.data.city);
      setState(response.data.state);
      setCpf(response.data.cpf);
      setPassword(response.data.password);
      setNeighborhood(response.data.neighborhood);
      setNickname(response.data.nickname);
      setBirth_date(moment(response.data.birth_date).format("YYYY-MM-DD"));
      setOccupation(response.data.occupation);
      setGender(response.data.gender);
      setHobbies(response.data.hobbies);
      setFacebook(response.data.facebook);
      setInstagram(response.data.instagram);
      setLoading(false);
    }
    carregaDados();
  }, [id]);
  if (loading) {
    return (
      <div className="alert alert-info">
        <i className="fas fa-spinner fa-pulse"></i>{" "}
        <strong>Carregando dados</strong>
      </div>
    );
  } else {
    return (
      <Card cardTitle="Informações do paciente">
        <section>
          <h6 className="title">Informações pessoais</h6>
          <div className="row">
            <div className="col">
              <strong>Nome: </strong> {name}
            </div>
            {nickname ? (
              <div className="col">
                <strong>Apelido: </strong> {nickname}
              </div>
            ) : (
              ""
            )}
            <div className="col">
              <strong>Sexo: </strong>
              {gender === "male"
                ? "Maculino"
                : gender === "female"
                ? "Feminino"
                : "Outros"}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <strong>Data de nascimento: </strong>
              {moment(birth_date).format("DD/MM/YYYY")}
            </div>
            <div className="col">
              <strong>trabalho: </strong>
              {occupation ? occupation : "Trabalho não cadastrado"}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <strong>Passa tempo: </strong>
              {hobbies ? hobbies : "Nenhum passa tempo cadastrado"}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <strong>Facebook: </strong>
              {facebook ? facebook : "Não cadastrado"}
            </div>
            <div className="col">
              <strong>Instagram: </strong>
              {instagram ? instagram : "Não cadastrado"}
            </div>
          </div>
        </section>
        <br />
        <section>
          <h6 className="title">Contato</h6>
          <div className="row">
            <div className="col">
              <strong>E-mail: </strong>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <strong>Telefone: </strong>
              {phone ? phone : "Sem telefone cadastrado"}
            </div>
            <div className="col">
              <strong>Celular: </strong>
              {mobile ? mobile : "Sem celular cadastrado"}
            </div>
          </div>
        </section>
        <br />
        <section>
          <h6 className="title">Endereço</h6>
          <div className="row">
            <div className="col">
              <strong>CEP: </strong>
              {postal_code ? postal_code : "Sem CEP cadastrado"}
            </div>
            <div className="col">
              <strong>Rua: </strong> {street ? street : "Sem rua cadastrado"}
            </div>
            <div className="col">
              <strong>Bairro: </strong>
              {neighborhood ? neighborhood : "Sem Bairro cadastrado"}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <strong>Complemento: </strong>
              {complement ? complement : "Sem Complemento cadastrado"}
            </div>
            <div className="col">
              <strong>Cidade: </strong>
              {city ? city : "Sem Cidade cadastrado"}{" "}
            </div>
            <div className="col">
              <strong>Estado: </strong>
              {state ? state : "Sem Estado cadastrado"}{" "}
            </div>
          </div>
          <br />
          <section>
            <h6 className="title">Dados de acesso</h6>
            <div className="row">
              <div className="col">
                <strong>Login: </strong>
                {cpf}
                <br />
                <strong>Senha: </strong>
                {password ? password : "Sem Senha cadastrado"}
              </div>
            </div>
          </section>
        </section>
      </Card>
    );
  }
}
